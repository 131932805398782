<template>
  <list-dashboards titleSmartsheet="Residencial" :typeSmartsheet="SMARTSHEET_TYPES.residential">
  
  </list-dashboards>
</template>

<script>
import ListDashboards from '../../../components/smartsheet/ListDashboards.vue'
import { SMARTSHEET_TYPES } from '@/constants/smartsheet'

export default {
  components: { ListDashboards },
  data(){
    return {
      SMARTSHEET_TYPES
    }
  }
}
</script>

<style>

</style>